<script setup>
const { t } = useI18n();

const props = defineProps({
  slug: String,
  odd: Boolean,
  even: Boolean,
});
const isExpanded = ref(false);
const projects = [
  {
    oficines_simon: {
      title: t("project.items.simon_offices_title"),
      subtitle: t("project.items.simon_offices_subtitle"),
      slug: "oficines-simon",
      description: "C. Sancho de Ávila, 66 Barcelona",
      header: "oficines-simon-7.jpg",
      items: [
        "oficines-simon-1.jpg",
        "oficines-simon-2.jpg",
        "oficines-simon-3.jpg",
        "oficines-simon-4.jpg",
        "oficines-simon-5.jpg",
        "oficines-simon-6.jpg",
      ],
    },
    residencia_vita_pedralbes: {
      title: t("project.items.residence_vita_pedralbes_title"),
      subtitle: t("project.items.residence_vita_pedralbes_subtitle"),
      slug: "residencia-vita-pedralbes",
      description:
        "Av. D\’Esplugues, 102, Les Corts, Barcelona",
      header: "residencia-vita-pedralbes-1.jpg",
      items: [
        "residencia-vita-pedralbes-2.jpg",
        "residencia-vita-pedralbes-3.jpg",
        "residencia-vita-pedralbes-4.jpg",
        "residencia-vita-pedralbes-5.jpg",
        "residencia-vita-pedralbes-6.jpg",
        "residencia-vita-pedralbes-7.jpg",
      ],
    },
    oller_del_mas: {
      title: t("project.items.oller_mas_title"),
      subtitle: t("project.items.oller_mas_subtitle"),
      slug: "oller-del-mas",
      description: "Ctra. d\’Igualada C-37z km 91 Manresa",
      header: "oller-del-mas-7.jpg",
      items: [
        "oller-del-mas-1.jpg",
        "oller-del-mas-2.jpg",
        "oller-del-mas-3.jpg",
        "oller-del-mas-4.jpg",
        "oller-del-mas-5.jpg",
        "oller-del-mas-6.jpg",
      ],
    },
    casa_vicens: {
      title: t("project.items.vicens_house_title"),
      subtitle: t("project.items.vicens_house_subtitle"),
      slug: "casa-vicens",
      description: "C. de les Carolines, 20-26, Gràcia, Barcelona",
      header: "casa-vicens-1.jpg",
      items: [
        "casa-vicens-2.jpg",
        "casa-vicens-9.jpg",
        "casa-vicens-3.jpg",
        // "casa-vicens-4.jpg",
        "casa-vicens-5.jpg",
        // "casa-vicens-6.jpg",
        "casa-vicens-7.jpg",
        "casa-vicens-8.jpg",
        // "casa-vicens-10.jpg",
      ],
    },
  },
];

const project = ref({});
const p = projects.find((p) => p[props.slug]);
if (p) project.value = p[props.slug];

const enter = (element) => {
  const width = getComputedStyle(element).width;

  element.style.width = width;
  element.style.position = "absolute";
  element.style.visibility = "hidden";
  element.style.height = "auto";

  const height = getComputedStyle(element).height;

  element.style.width = null;
  element.style.position = null;
  element.style.visibility = null;
  element.style.height = 0;

  // Force repaint to make sure the
  // animation is triggered correctly.
  getComputedStyle(element).height;

  // Trigger the animation.
  // We use `requestAnimationFrame` because we need
  // to make sure the browser has finished
  // painting after setting the `height`
  // to `0` in the line above.
  requestAnimationFrame(() => {
    element.style.height = height;
  });
};

const afterEnter = (element) => {
  element.style.height = "auto";
};

const leave = (element) => {
  const height = getComputedStyle(element).height;

  element.style.height = height;

  // Force repaint to make sure the
  // animation is triggered correctly.
  getComputedStyle(element).height;

  requestAnimationFrame(() => {
    element.style.height = 0;
  });
};
</script>

<style lang="css" scoped>
.background-before::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  pointer-events: none;
}

/* CSS for the transition */
.expand-enter-active,
.expand-leave-active {
  transition: height 0.99s ease-in-out;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
}

* {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
</style>

<template>
  <div>
    <div
      class="duration-700 transition-[height] background-before w-full h-[500px] lg:h-[865px] bg-cover bg-center relative"
      :class="{ '!h-[340px]': isExpanded }" :style="{
        backgroundImage: `url('/projects/${project.slug}/${project.header}')`,
      }">
      <div class="bg-transparent duration-700 transition-all h-full absolute w-full opacity-50"
        :class="{ '!bg-white': isExpanded }"></div>
      <div class="mx-auto h-full flex max-w-7xl px-8 lg:px-[60px] py-[110px]" :class="{
        'py-[40px]': isExpanded,
        'items-end lg:justify-end': props.even,
        'items-end lg:justify-start': props.odd,
      }">
        <div class="flex flex-col z-10 relative">
          <div class="flex flex-col border-b pb-4 mb-6"
            :class="{ 'items-start lg:items-end': props.even, 'items-start lg:items-start': props.odd }">
            <div class="text-white font-light uppercase text-5xl lg:text-7xl" v-if="project.title">
              {{ project.title }}
            </div>
            <div class="text-white font-bold uppercase text-5xl lg:text-7xl" v-if="project.subtitle">
              {{ project.subtitle }}
            </div>
          </div>
          <div class="flex" :class="{
            'items-start lg:justify-end': props.even,
            'items-start lg:justify-start': props.odd,
          }">
            <AnchorButton @click="isExpanded = !isExpanded" :label="isExpanded ? $t('project.close') : $t('project.view_project')
              " />
          </div>
        </div>
      </div>
    </div>
    <Transition name="expand" @enter="enter" @after-enter="afterEnter" @leave="leave">
      <div v-show="isExpanded" class="w-full overflow-hidden">
        <div class="px-10 py-10 lg:y-20">
          <!-- <div class="flex flex-col py-8 text-[#515151]">
            <div
              class="font-light uppercase text-6xl"
              v-if="project.title?.length"
            >
              {{ project.title[0] }}
            </div>
            <div
              class="font-bold uppercase text-6xl"
              v-if="project.title?.length === 2"
            >
              {{ project.title[1] }}
            </div>
          </div> -->
          <div class="w-full pb-8 text-2xl">
            {{ project.description }}
          </div>
          <div>
            <masonry-wall :items="project.items" :ssr-columns="1" :column-width="400" :gap="16">
              <template #default="{ item, index }">
                <div :style="{ height: `auto` }">
                  <img class="w-full" :src="`/projects/${project.slug}/${item}`" :style="{ height: `auto` }" />
                </div>
              </template>
            </masonry-wall>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>
