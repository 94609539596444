<script setup></script>

<template>
  <Menu />
  <ProjectAccordeon slug="oficines_simon" odd />
  <ProjectAccordeon slug="residencia_vita_pedralbes" even />
  <ProjectAccordeon slug="oller_del_mas" odd />
  <ProjectAccordeon slug="casa_vicens" even />
  <Footer />
</template>
